import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'base',
        component: () => import('@/App.vue'),
        redirect: {
            name: 'index'
        }
    },
    {
        path: '/',
        component: () => import('@/layout/layout.vue'),
        children: [
            {
                path: 'index',
                component: () => import('@/views/home/index.vue'),
                name: 'index'
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router