import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 10 // request timeout
})

// request interceptor
service.interceptors.request.use(

  config => {
	// config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
	if(res.errcode == 1){
		return res
	} else if(res.errcode === 401 || res.errcode === 403 || res.errcode === 504 || res.errcode === 500) {
		return res
		// return Promise.reject(new Error(res.message || 'Error'))
	} else{
		return res
	}
  },
  error => {
    
  }
)

const shareService = axios.create({
  baseURL: import.meta.env.VITE_APP_SHARE_API,
  timeout: 1000 * 60 * 10 
})

shareService.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(new Error('Error'))
  }
)

export {
  shareService
}

export default service
