<template>
  <RouterView></RouterView>
</template>
<script setup>
import Share from '@/utils/share'
Share.setShareInfo({
  title: '囍 ♡ 吃席啦 ♡ 囍',
  desc: '🥰王硕 & 吕颜 | 婚礼邀请函',
  link: 'https://weddings.wclouder.cn',
  imgUrl: 'https://weddings.wclouder.cn/shareImg.jpg'
})
// import VConsole from 'vconsole';

// new VConsole();
</script>
<style lang="scss">
body {
  background-image: url(./assets/orange_bg.jpg);
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: 0 0;
}
</style>