import wx from 'weixin-js-sdk'
import { getShareSign } from '@/api/share'
class Share {
  constructor() {
    this.inited = false
  }
  
  static get instance() {
    if(!this._inst) {
      this._inst = new Share()
    }
    return this._inst
  }

  async _initConfig() {
    const url = window.location.href.split('#')[0]
    const result = await getShareSign({ url })
    if(result && result.code === 200) {
      const { 
        appId,
        timestamp,
        nonceStr,
        signature, 
      } = result.data
      wx.config({
				debug: false,
				appId,
				timestamp,
				nonceStr,
				signature,
				jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'openLocation'],
				openTagList:["wx-open-launch-weapp","wx-open-launch-app"]
			})
    }
  }

  async setShareInfo(info) {
    if(!this.inited) {
      await this._initConfig()
    }
    const {
      title,
      desc,
      link,
      imgUrl
    } = info
    wx.ready(() => {
      wx.updateAppMessageShareData({ 
        title, // 分享标题
        desc, // 分享描述
        link: link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: function () {
          // 设置成功
          console.log('success')
        }
      })
      wx.updateTimelineShareData({ 
        title, // 分享标题
        desc, // 分享描述
        link: link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: function () {
          // 设置成功
          console.log('success')
        }
      })
    })
  }
}

const shareController = Share.instance
export default shareController