import { shareService } from '@/request'

/**
 * 获取商品列表
 */
export const getShareSign = (params) => shareService({
  url: 'wxapi/wxGetSharesign',
  method: 'get',
  params
})
