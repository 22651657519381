import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'

import useStepStore from './modules/useStepStore'
import useUserStore from './modules/useUserStore'

const pinia = createPinia()
pinia.use(piniaPersist)

export default pinia

export {
    useStepStore,
    useUserStore
}
